let config = {
    s3: {
        REGION: "ap-southeast-2",
        BUCKET: "forwood-newcrest-staging-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-newcrest-staging-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "ap-southeast-2",
        URL: "https://egstmibt7jbornmszyvwteh7sm.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "ap-southeast-2",
        URL: "https://bsnox014sf.execute-api.ap-southeast-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://jh2sn3ew45.execute-api.ap-southeast-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.staging.newcrest.forwoodsafety.com",
        WEBSOCKET: "wss://j12xi7i7gd.execute-api.ap-southeast-2.amazonaws.com/staging"
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_2m3LORGQ4",
        IDENTITY_POOL_ID: "ap-southeast-2:82c8ecc3-199c-41f8-b587-c3d84c4d90ed",
        USERPOOL_HOSTED_DOMAIN: "forwood-newcrest-id-staging",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.staging.newcrest.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.staging.newcrest.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::187736755686:role/staging-CA"
    },
    samlProvider: {
      NAME: 'newcrest',
      SUPPORTED_LIST: 'newcrest,ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.35.6",
        HOSTNAME: "id.staging.newcrest.forwoodsafety.com",
        ENV_NAME: "staging",
        COOKIE_DOMAIN: ".staging.newcrest.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "44302b9b-5168-4406-9c98-35b636528121",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.staging.newcrest.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
